import styled, { css } from 'styled-components'

import arrowRight from '../../images/arrow-right.svg'

const SmallButton = css`
  display: inline-flex;
  line-height: initial;
  border-radius: 4px;
  padding: 0.7rem 1rem;
  margin: 0 1rem;
  height: 36px;
`

const LargeButton = css`
  font-size: 1.28rem;
  font-weight: bold;
  padding: 1.2rem 3rem;
  border-radius: 8px;
`

const Transparent = css`
  font-size: 1.28rem;
  font-weight: bold;
  background: transparent;
  border: 1px solid #c5c5c5;
  border-radius: 8px;
  padding: 1.2rem 3rem;
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  box-shadow: none;
`

const ArrowButton = css`
  ${LargeButton}
  background-image: url(${arrowRight});
  background-position: 93% center;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
  padding: 1.3rem 4rem 1.2rem 1.3rem;
  z-index: 1;

  @media (max-width: 768px) {
    align-self: center;
  }
`
const getButtonStyles = (props) => {
  const { type } = props
  switch (type) {
    case 'small':
      return SmallButton
    case 'large':
      return LargeButton
    case 'arrow':
      return ArrowButton
    case 'transparent':
      return Transparent
  }
}

export const ButtonLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  width: auto;
  height: auto;
  text-transform: uppercase;
  white-space: nowrap;
  ${({ isHeadroom, inverted, theme }) =>
    !isHeadroom
      ? css`
          background: ${!inverted ? theme.colors.vividBlue : theme.colors.lightestGrey};
        `
      : css`
          background: ${theme.colors.vividBlue};
        `}
  ${({ isHeadroom, inverted, theme }) =>
    !isHeadroom
      ? css`
          color: ${!inverted ? theme.colors.pureWhite : theme.colors.veryDarkBlue};
        `
      : css`
          color: ${theme.colors.pureWhite};
        `}
  
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.15);
  cursor: pointer;
  will-change: transform, box-shadow;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.5);
    transform: translateY(-3px);
    ${({ isHeadroom, inverted }) =>
      !isHeadroom
        ? css`
            box-shadow: ${inverted ? '0px 4px 10px rgba(255, 255, 255, 0.5)' : '0px 4px 10px rgba(0, 133, 255, 0.5)'};
          `
        : null}
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.3);
  }

  &::selection {
    color: none;
    background: none;
  }

  ${getButtonStyles}
`
