import React from 'react'
import PropTypes from 'prop-types'

import { ButtonLink } from './styles'

const Button = ({ children, ...props }) => {
  return <ButtonLink {...props}>{children}</ButtonLink>
}

export default Button

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['small', 'large', 'arrow', 'transparent'])
}
